<template>
  <div>
    <b-modal
      ref="upgrade-downgrade-feature"
      centered
      hide-footer
      class="position-relative"
      title="Upgradion and degradation of features/packages"
    >
      <b-row class="m-3">
        <b-col>
          <b-card
            text-variant="white"
            bg-variant="primary"
            align="center"
            class="position-static"
            @click="upgradeDowngradePackages()"
          >
            <blockquote class="blockquote  mb-0">
              <p>Upgrade/Downgrade packages</p>

            </blockquote>
          </b-card>
        </b-col>
        <b-col>
          <b-card
            text-variant="white"
            bg-variant="primary"
            align="center"
            class="position-static"
            @click="upgradeDowngradeFeatures()"
          >
            <blockquote class="blockquote mb-0">
              <p>Upgrade/Downgrade features</p>
            </blockquote>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BRow, BCol, BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BCard,
  },
  props: {
    members: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {

    }
  },
  methods: {
    show() {
      this.$refs['upgrade-downgrade-feature'].show()
    },
    hide() {
      this.$refs['upgrade-downgrade-feature'].hide()
    },
    upgradeDowngradeFeatures() {
      console.log('Upgrade Features')
    },
    upgradeDowngradePackages() {
      console.log('Upgrade Packages')
    },
  },
}
</script>
<style lang="scss">

.card{
  cursor: pointer;
}
</style>
